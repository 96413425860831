import styled from 'styled-components';
import { motion } from 'framer-motion';

export const MenuContainer = styled(motion.div)`
  position:fixed;
  z-index:18;
  width:100%;
  display:flex;
  flex-direction:column;  
  overflow:hidden;
  background-color:rgba(22,22,22, 0.95);

  .content{
    padding-left:130px;
    display:flex;
    flex-direction:column;  
    justify-content:center;
    align-items:flex-start;
    flex-basis:90%;
  
    .menu-text-container{
      display:inline-block;
      overflow:hidden;
    }
    
    // Small devices (landscape phones, 576px and up)
    @media (min-width: 200px) and (max-width: 767.98px) { 
      padding-left:40px;
    }

  }

  .footer{
    padding: 0px 130px;
    flex-basis:10%;
    color:white;
    display:flex;
    justify-content:space-between;
    align-items:flex-start;
    
    // Small devices (landscape phones, 576px and up)
    @media (min-width: 200px) and (max-width: 767.98px) { 
      padding:0px 30px;
      flex-direction:column;
    }
    
    .social-networks-menu{
      overflow:hidden;
      
      // Small devices (landscape phones, 576px and up)
      @media (min-width: 200px) and (max-width: 767.98px) { 
        order:0;
        width:100%;
        display:flex;
        justify-content:space-around;
      }
    }
  }
`;

export const CarbonRightsText = styled(motion.span)`
  font-size: 14px;
  color: #FFFFFF;

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 200px) and (max-width: 767.98px) { 
    order:1;
    text-align:center;
    width:100%;
    padding:20px 0px;
  }
`;

export const SocialNetworksText = styled(motion.a)`
    color:white;
    display:inline-block;
    margin-left:40px;
    text-decoration:none;
    
    // Small devices (landscape phones, 576px and up)
    @media (min-width: 200px) and (max-width: 767.98px) { 
      margin:0px;
    }
    
`;

export const MenuText = styled(motion.div)`
    font-size: 48px;
    line-height: 170%;
    color: #FFFFFF;
    cursor:pointer;
`;

