import styled from 'styled-components';

export const TalkToUsContainer = styled.div`
    display:flex;
    align-items:center;
    cursor: pointer;
    width:fit-content;

    margin-left:${props => props.marginLeft};

    span{
        letter-spacing: -0.015em;
        color: ${props => props.color};
        margin-right:10px;

        // Small devices (landscape phones, 576px and up)
        @media (max-width: 200px) and (max-width: 767.98px) { 
            font-size: 15px;
        }

        @media (min-width: 768px) { 
            font-size: ${props => props.fontSize};
        }
    }

    img{
        // Small devices (landscape phones, 576px and up)
        @media (min-width: 200px) and (max-width: 767.98px) { 
            width:26px;
        }

        @media (min-width: 768px) { 
            width:35px;
        }
    }

`;