import React, {memo} from 'react';
import { CarbonLogoStyle } from './styles';
import CarbonLogoWhite from '../../assets/images/carbon-logo-white.svg';
import { Link } from 'react-router-dom';

function CarbonIcon() {
    return (
        <CarbonLogoStyle>
            <Link to="/">
                <img src={CarbonLogoWhite} alt="" />
            </Link>
        </CarbonLogoStyle>
    )
}

export default memo(CarbonIcon);