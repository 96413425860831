import React, { useEffect } from 'react';
import { MenuContainer, MenuText, SocialNetworksText, CarbonRightsText } from './styles';
import { useAnimation } from 'framer-motion';
import { useHistory } from 'react-router-dom';

export default function Menu({ isOpen, onClose }) {

    const history = useHistory();

    const menuContainerControl = useAnimation();
    const menuTextControl = useAnimation();
    const menuSocialNetworkTextControl = useAnimation();
    const menuCarbonRightsTextControl = useAnimation();

    const menuContainerVariants = {
        disabled: {
            height: "0px",
            transition: {
                delay: 0.8,
                duration: 0.5
            }
        },
        enabled: {
            height: "100%",
            transition: {
                stiffness: 0,
                duration: 1
            }
        }
    }

    const menuTextVariants = (delay) => {
        return {
            from: {
                y: 100,
                transition: {
                    delay: delay - 0.2,
                    siffness: 0
                }
            },
            to: {
                y: 0,
                transition: {
                    delay,
                    ease: "easeInOut",
                    siffness: 0
                }
            },
        }
    }

    const menuSocialNetworkTextVariants = (delay) => {
        return {
            from: {
                y: 100,
                transition: {
                    delay: delay - 1,
                    siffness: 0
                }
            },
            to: {
                y: 0,
                transition: {
                    delay,
                    ease: "easeInOut",
                    siffness: 0
                }
            },
        }
    }

    const menuCarbonRightsTextVariants = {

        from: {
            opacity: 0,
            transition: {
                delay: 0.2
            }
        },
        to: {
            opacity: 1,
            transition: {
                delay: 1
            }
        }

    }

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflowY = "hidden";
            menuContainerControl.start('enabled');
            menuTextControl.start('to');
            menuSocialNetworkTextControl.start('to');
            menuCarbonRightsTextControl.start('to');
        } else {
            menuContainerControl.start('disabled').then(() => {
                document.body.style.overflowY = "auto";
            });;
            menuTextControl.start('from');
            menuSocialNetworkTextControl.start('from');
            menuCarbonRightsTextControl.start('from');
        }
    }, [isOpen, menuCarbonRightsTextControl, menuContainerControl, menuSocialNetworkTextControl, menuTextControl]);

    const changeRoute = (route) => {
        onClose();
        history.push(route);
    }

    return (
        <MenuContainer animate={menuContainerControl} initial="disabled" variants={menuContainerVariants}>
            <div className="content">
                <div className="menu-text-container" onClick={() => changeRoute("/")}>
                    <MenuText to="/" initial="from" animate={menuTextControl} variants={menuTextVariants(0.6)}>Home</MenuText>
                </div>
                <div className="menu-text-container" onClick={() => changeRoute("/talk-to-us")}>
                    <MenuText initial="from" animate={menuTextControl} variants={menuTextVariants(0.7)}>Contact</MenuText>
                </div>
                <div className="menu-text-container" onClick={() => window.open("https://vagasnoexterior.carbonlab.tech")}>
                    <MenuText initial="from" animate={menuTextControl} variants={menuTextVariants(0.8)}>Jobs USA (Be Hired)</MenuText>
                </div>
            </div>
            <div className="footer">
                <CarbonRightsText initial="from" animate={menuCarbonRightsTextControl} variants={menuCarbonRightsTextVariants}>&copy; Carbon Lab 2021 all rights reserved.</CarbonRightsText>
                <div className="social-networks-menu">
                    <SocialNetworksText variants={menuSocialNetworkTextVariants(1)} initial="from" animate={menuSocialNetworkTextControl} href="https://www.facebook.com/Carbon-Lab-102272208503341" target="_blank" rel="noreferrer">facebook</SocialNetworksText>
                    <SocialNetworksText variants={menuSocialNetworkTextVariants(1.2)} initial="from" animate={menuSocialNetworkTextControl} href=" https://www.instagram.com/carbonlab.tech" target="_blank" rel="noreferrer">instagram</SocialNetworksText>
                    <SocialNetworksText variants={menuSocialNetworkTextVariants(1.4)} initial="from" animate={menuSocialNetworkTextControl} href="https://www.linkedin.com/company/carbon-lab" target="_blank" rel="noreferrer">linkedIn</SocialNetworksText>
                </div>
            </div>
        </MenuContainer>
    )
}
