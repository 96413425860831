import React from 'react';
import { ThankYouContainer, ThankYouTextContainer, BackToWebsiteContainer } from './styles';
import ArrowUpIconWhite from '../../assets/icons/arrow-up-icon-white.svg';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';

export default function ThankYou() {
    const history = useHistory();

    const SplitText = (props) => {

        const children = props.children.split(' ');

        return (
            <ThankYouTextContainer>
                {
                    React.Children.map(children, (child, i) => {
                        return (
                            <motion.span style={{marginRight:"20px"}} transition={{delay: 0.15 * i}} initial={{y:100, opacity:0}} animate={{y:0, opacity:1}}>
                                {child}
                            </motion.span>
                        );
                    })
                }
            </ThankYouTextContainer>
        )
    }

    return (
        <ThankYouContainer>
            <SplitText>We'll be in touch soon.</SplitText>
            <BackToWebsiteContainer onClick={() => history.push('/')} transition={{delay: 1}} initial={{opacity:0}} animate={{opacity:1}}>
                <span>back to website</span>
                <img src={ArrowUpIconWhite} alt="" />
            </BackToWebsiteContainer>
        </ThankYouContainer>
    )
}
