import styled from 'styled-components';

export const Input = styled.input`
    height:40px;
    width:100%;
    background:none;
    border:none;
    border-bottom:1px solid white;

    font-size: 18px;
    line-height: 161.5%;
    letter-spacing: -0.015em;
    color: #FFFFFF;

    ::placeholder{
        font-size: 18px;
        line-height: 161.5%;
        letter-spacing: -0.015em;
        color: #FFFFFF;
    }

    :focus{
        outline:none;
        border-bottom:2px solid white;
    }
`;