import React, {memo} from 'react';
import { TalkToUsContainer } from './styles';
import ArrowUpIconWhite from '../../assets/icons/arrow-up-icon-white.svg';
import ArrowUpIconBlack from '../../assets/icons/arrow-up-icon-black.svg';
import { useHistory } from 'react-router-dom';

function TalkToUs({ color = "white", marginLeft = "0px", fontSize="18px" }) {
    const history = useHistory();
    
    return (
        <TalkToUsContainer onClick={() => history.push('/talk-to-us')} fontSize={fontSize} color={color} marginLeft={marginLeft}>
            <span>talk to us</span>
            <img src={color === "white" ? ArrowUpIconWhite : ArrowUpIconBlack } alt="" />
        </TalkToUsContainer>
    )
}

export default memo(TalkToUs);