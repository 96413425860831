import React, { useRef, useEffect } from 'react';
import { SliderSection as HomeSliderSection } from '../styles';

import GetAQuote from '../../../components/slider-item/GetAQuote';
import SpecificGravityPhoto from '../../../assets/images/slider/specific-gravity.png';
import LiveTimeless from '../../../assets/images/slider/live-timeless.png';
import Crypties from '../../../assets/images/slider/crypties.png';
import Myrp from '../../../assets/images/slider/myrp.png';
// import AlbatrozPhoto from '../../../assets/images/slider/albatroz.png';
// import NearYourHousePhoto from '../../../assets/images/slider/near-your-house.png';

import SliderItem from '../../../components/slider-item/SliderItem';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function SliderSection() {

    const slideContainer = useRef(null);
    const slideItems = useRef([]);

    const slideItemsData = [
        {
            slideImage: Crypties,
            slideTitle: "Crypties",
            slideColor: "#26A28C",
            buildedWith: "react js",
            projectLink: 'https://crypties.ch'
        },
        {
            slideImage: Myrp,
            slideTitle: "Myrp",
            slideColor: "#01A2DA",
            buildedWith: "wordpress",
            projectLink: 'https://myrp.com.br'
        },
        {
            slideImage: SpecificGravityPhoto,
            slideTitle: "Specific Gravity",
            slideColor: "#FE483D",
            buildedWith: "wordpress",
            projectLink: 'https://www.sgbevco.com'
        },
        {
            slideImage: LiveTimeless,
            slideTitle: "Live Timeless",
            slideColor: "#001B38",
            buildedWith: "react native",
            projectLink: ''
        },
    ];
    // {
    //     slideImage: AlbatrozPhoto,
    //     slideTitle: "Albatroz",
    //     slideColor: "#EAA315",
    //     buildedWith: "wordpress"
    // },
    // {
    //     slideImage: NearYourHousePhoto,
    //     slideTitle: "Near Your House",
    //     slideColor: "#D3B198",
    //     buildedWith: "wordpress"
    // },

    useEffect(() => {
        gsap.to(slideItems.current, {
            x: () => -(slideContainer.current ? slideContainer.current.scrollWidth - document.documentElement.clientWidth : 0) + "px",
            ease: "none",
            scrollTrigger: {
                trigger: slideContainer.current,
                invalidateOnRefresh: true,
                pin: true,
                scrub: 1,
                // end: () => "+=" + slideContainer.current.offsetWidth
            }
        });

    }, [slideItems]);

    const addToRefs = el => {
        if (el && !slideItems.current.includes(el)) {
            slideItems.current.push(el);
        }
    };

    return (
        <HomeSliderSection ref={slideContainer}>

            {slideItemsData.map((data, index) => (
                <SliderItem key={index} bgImage={data.slideImage}
                    projectLink={data.projectLink}
                    backgroundColor={data.slideColor}
                    buildedWith={data.buildedWith}
                    index={index}
                    name={data.slideTitle}
                    ref={addToRefs} />
            ))}

            <GetAQuote ref={addToRefs} />
        </HomeSliderSection>
    )
}
