import React, {memo} from 'react';
import { LanguagesSwitchContainer } from './styles';

function SocialNetworks({ color = "white" }) {
    return (
        <LanguagesSwitchContainer color={color}>
            <a href="https://www.facebook.com/Carbon-Lab-102272208503341" target="_blank" rel="noreferrer">fa</a>
            <a href=" https://www.instagram.com/carbonlab.tech" target="_blank" rel="noreferrer">ig</a>
            <a href="https://www.linkedin.com/company/carbon-lab" target="_blank" rel="noreferrer">in</a>
        </LanguagesSwitchContainer>
    )
}

export default memo(SocialNetworks);