import styled from 'styled-components';

export const LanguagesSwitchContainer = styled.div`
    padding:10px 0;

  a{
    text-decoration: none;

    color:white;
    mix-blend-mode:difference;

    margin-right:10px;
    font-size: 14px;
  }
`;