import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyleSliderItem = styled(motion.div)`
  width:33.3vw;
  height:100%;
  border-right: 1px solid white;
  flex-shrink:0;
  cursor: pointer;

  position:relative;
  overflow:hidden;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 200px) and (max-width: 767.98px) { 
    width:100vw;
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    width:50vw;
  }

  .get-quote-container{
    height:100%;
    color: #FFFFFF;
    display:flex;
    padding-left:80px;

    flex-direction:column;
    justify-content:center;


    h3{
      font-weight: normal;
      font-size: 40px;
    }

    p{
      margin-top:10px;
      font-weight: normal;
      font-size: 24px;
    }
  }
`;

export const StyleSliderItemContent = styled.div`
  display:flex;
  flex-direction:column;
  width:100%;
  height:100%;

  position:absolute;
  z-index:5;
`;

export const StyleSliderItemHeader = styled.div`
  display:flex;
  justify-content:flex-end;
  padding-top:30px;
  padding-right:30px;
`;

export const StyleContentContainer = styled(motion.div)`
  display:flex;
  flex:1;
  align-items:center;
`;

export const StyleContentTextsContainer = styled.div`
  color:white;
  padding-left:80px;
  display:flex;
  flex-direction:column;
`;

export const StyleContentText = styled.span`
  font-size:${props => props.fontSize};
  font-family:${props => props.fontFamily};
`;

export const StyleBuildedWithContainer = styled.div`
  background-color: #1C1C1C;
  width:fit-content;
  padding:10px;

  p{
    color:white;
  }
`;

export const BackgroundColor = styled(motion.div)`
  position:absolute;
  z-index:4;
  
  width:100%;
  height:100%;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  background-color:${props => props.backgroundColor};
`;

export const BackgroundImage = styled(motion.img)`
  position:absolute;
  z-index:6;
  width:100%;
  height:100%;

  pointer-events: none;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
  object-fit:cover;
  border:none;
  bottom:-20px;
  right:-20px;
`;