import styled from 'styled-components';
import { motion } from 'framer-motion';

export const TalkToUsContainer = styled.section`
    display:flex;
    background-color:#161616;
    height:100vh;

    // Small devices (landscape phones, 576px and up)
    @media (min-width: 200px) and (max-width: 991.98px) { 
        flex-direction:column;
        height:auto;
    }

  .contents-container{
    display:flex;
    flex-basis:60%;
    flex-direction:column;
    justify-content:center;
    padding-left:110px;

    // Small devices (landscape phones, 576px and up)
    @media (min-width: 200px) and (max-width: 991.98px) { 
        margin-top:100px;
        padding:20px;
    }

    button{
        cursor:pointer;
        display:flex;
        justify-content:center;
        align-items:center;
        margin-top:50px;
        width: 300px;
        height:78px;
        background-color:white;
        border:none;

        span{
            margin-right:20px;
            font-size: 24px;
            color: #000000;
            padding-bottom:3px;
        }

        // Small devices (landscape phones, 576px and up)
        @media (min-width: 200px) and (max-width: 991.98px) { 
            width: 250px;
            height:60px;

            span{
                font-size: 20px;
            }
        }

    }
}
`;
export const Title = styled(motion.span)`
    font-size: 36px;
    line-height: 161.5%;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    white-space:pre-line;

    // Small devices (landscape phones, 576px and up)
    @media (min-width: 200px) and (max-width: 991.98px) { 
        font-size: 26px;
        white-space:normal;
    }
`;

export const InputsContainer = styled(motion.div)`
    margin-top:60px;

    .customer-inputs-container{
        display:flex;

        .right-container{
            display:flex;
            flex-direction:column;
            flex-basis:50%;
            margin-right:25px;
        }
        .left-container{
            display:flex;
            flex-direction:column;
            flex-basis:50%;
        }
    }
`;

export const InputsErrorText = styled.span`
    color:white;
    font-size:15px;
    margin:15px 0px;
`;

export const MapContainer = styled.div`
    flex-basis:40%;
    padding:0px 30px;

    // Small devices (landscape phones, 576px and up)
    @media (min-width: 200px) and (max-width: 767.98px) { 
        width:100%;
    }
`;

export const Loader = styled.div`
    font-size: 10px;
    text-indent: -9999em;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #000000;
    background: -moz-linear-gradient(left, #000000 10%, rgba(0,0,0, 0) 42%);
    background: -webkit-linear-gradient(left, #000000 10%, rgba(0,0,0, 0) 42%);
    background: -o-linear-gradient(left, #000000 10%, rgba(0,0,0, 0) 42%);
    background: -ms-linear-gradient(left, #000000 10%, rgba(0,0,0, 0) 42%);
    background: linear-gradient(to right, #000000 10%, rgba(0,0,0, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);

    :before{
        width: 50%;
        height: 50%;
        background: #000000;
        border-radius: 100% 0 0 0;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
    }

    :after{
        background: white;
        width: 75%;
        height: 75%;
        border-radius: 50%;
        content: '';
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    @-webkit-keyframes load3 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }   
    
    @keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    }



`;