import React, { forwardRef } from 'react';
import { StyleSliderItem } from './styles';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';

const GetAQuote = forwardRef((props, ref) => {

    const history = useHistory();

    const spring = {
        type: "spring",
        damping: 10,
        duration: 0.4
    }

    const variant = {
        from: {
            y: 0
        },
        hover: {
            y: -50
        }
    }

    return (
        <StyleSliderItem ref={ref} onClick={()=> history.push('/talk-to-us')}>
            <motion.div initial="from" transition={spring} whileHover="hover" animate="from" variants={variant} className="get-quote-container">
                <h3>Get a quote</h3>
                <p>Let's start your project</p>
            </motion.div>
        </StyleSliderItem>
    )
});

export default GetAQuote;