import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ThankYouContainer = styled.section`
    height:100vh;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
    padding-left:130px;
    background-color:#161616;

    // Small devices (landscape phones, 576px and up)
    @media (min-width: 200px) and (max-width: 767.98px) { 
        padding-left:20px;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        padding-left:70px;
    }
`;

export const BackToWebsiteContainer = styled(motion.div)`
    cursor:pointer;
    margin-top:15px;
    color:white;

    display:flex;
    align-items:center;

    span{
        font-size: 18px;
        color: #FFFFFF;
    }
`;

export const ThankYouTextContainer = styled.div`
    overflow:hidden;
    display: inline-block;

    span{
        font-size: 72px;
        color: #FFFFFF;
        display: inline-block;
    }

    // Small devices (landscape phones, 576px and up)
    @media (min-width: 200px) and (max-width: 767.98px) { 
        span{
            font-size: 50px;
        }
    }
`;