import React, { useEffect } from 'react';
import { TheExtraTalentSection, HomeTextTitle } from '../styles';
import TalkToUs from '../../../components/talk-to-us/TalkToUs';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export default function TheExtraTalent() {

    const [theExtraTalentSectionRef, InView] = useInView({ threshold: 0.7, triggerOnce: true });

    const titleControl = useAnimation();
    const titleVariants = {
        from: {
            x: -1000
        },
        to: {
            x: 0
        }
    }

    const talkToUsControl = useAnimation();
    const talkToUsVariants = {
        from: {
            scale: 0,
            transition: { delay: 0.6 }
        },
        to: {
            scale: 1,
            transition: { delay: 0.6 }
        }
    }

    const descriptionControl = useAnimation();
    const descriptionVariants = {
        from: {
            opacity: 0,
            transition: { delay: 0.3 }
        },
        to: {
            opacity: 1,
            transition: { delay: 0.3 }
        }
    }

    useEffect(() => {
        if (InView) {
            descriptionControl.start("to");
            titleControl.start("to");
            talkToUsControl.start("to");
        }
    }, [InView, descriptionControl, talkToUsControl, titleControl])

    return (
        <TheExtraTalentSection ref={theExtraTalentSectionRef}>
            <HomeTextTitle transition={{ type: "spring", stiffness: 50, duration: 1 }} initial="from" variants={titleVariants} animate={titleControl} black dangerouslySetInnerHTML={{
                __html: `The extra talent needed
            to boost your projects!`
            }} />

            <motion.p initial="from" animate={descriptionControl} variants={descriptionVariants} dangerouslySetInnerHTML={{
                __html: `Increase your team with talents that deliver speed, professional insight and flexibility. 
            We select and prepare the best professionals to offer you agile and scalable results so that your
            company can carry out the projects within the estimated time.

            It’s simple: your extended team will work as part of the local team, taking care of 
            your daily routine with meetings and reports which are dealt with directly by the managers, 
            proving to be a true competitive differential for your business.`
            }} />

            <div style={{ display: "flex" }}>
                <motion.div initial="from" animate={talkToUsControl} variants={talkToUsVariants}>
                    <TalkToUs color="black" fontSize="28px" />
                </motion.div>
            </div>
        </TheExtraTalentSection>
    )
}
