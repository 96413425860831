import React, {useEffect} from 'react';
import { ApresentationSection, HomeTextTitle } from '../styles';

import { motion, useViewportScroll, useTransform, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import TalkToUs from '../../../components/talk-to-us/TalkToUs';

export default function Apresentation() {

    const { scrollY } = useViewportScroll();

    const yTitleApresentationScroll = useTransform(scrollY, [0, 1000], [-250, 0]);
    const [apresentationSectionRef, IsApresentationSectioninView] = useInView({ threshold: 0.8, triggerOnce: true });
    const apresentationSectionControl = useAnimation();
    const apresentationSectionVariants = {
        from: {
            x: -500
        },
        to: {
            x: 0
        }
    }

    useEffect(() => {
        if (IsApresentationSectioninView) {
            apresentationSectionControl.start("to");
        } else {
            apresentationSectionControl.start("from");
        }
    }, [apresentationSectionControl, IsApresentationSectioninView]);

    return (
        <ApresentationSection ref={apresentationSectionRef}>
            <div className="apresentation-content-container">
                <HomeTextTitle fontSize="45px" style={{ y: yTitleApresentationScroll }} black dangerouslySetInnerHTML={{
                    __html: `We look for customers who share our philosophy
                    and understand the importance of simplifying, 
                    generating more value and succeeding in their 
                    business through technology.`
                }} />

                <motion.div initial="from" animate={apresentationSectionControl} variants={apresentationSectionVariants}>
                    <TalkToUs color="black" fontSize="28px" />
                </motion.div>
            </div>
        </ApresentationSection>
    )
}
