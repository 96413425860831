import React, { useEffect } from 'react';
import TalkToUs from '../../../components/talk-to-us/TalkToUs';
import { TalkToUsHomeSection, HomeTextTitle, BackgroundVideo } from '../styles';
import TalkToUsVideo from '../../../assets/videos/home-taltk-to-us-video.mp4'
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export default function TalkToUsSection() {

    const [talkToUsContainerRef, isInView] = useInView({ threshold: 0.7, triggerOnce:true });

    const titleControl = useAnimation();
    const talkToUsControl = useAnimation();

    const titleVariants = {
        from: {
            opacity: 0,
            y: 300
        },
        to: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.7
            }
        }
    }

    const talkToUsVariants = {
        from: {
            x: -1000
        },
        to: {
            x: 0,
            transition:{
                delay:0.7
            }
        }
    }

    useEffect(() => {
        if (isInView) {
            titleControl.start('to');
            talkToUsControl.start('to');
        }

    }, [isInView, titleControl, talkToUsControl])

    return (
        <TalkToUsHomeSection ref={talkToUsContainerRef}>
            <div className="talk-to-us-home-content">
                <HomeTextTitle initial="from" animate={titleControl} variants={titleVariants} dangerouslySetInnerHTML={{
                    __html: `Talk to us and get to know how we 
                        can make your idea come alive.`
                }} />

                <motion.div animate={talkToUsControl} initial="from" variants={talkToUsVariants}>
                    <TalkToUs color="white" fontSize="28px" />
                </motion.div>
            </div>

            <BackgroundVideo src={TalkToUsVideo} loop autoPlay muted />

        </TalkToUsHomeSection>
    )
}
