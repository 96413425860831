import React, { useState } from 'react';
import TalkToUsInput from '../../components/talk-to-us-input/TalkToUsInput';
import { TalkToUsContainer, InputsContainer, MapContainer, Loader, InputsErrorText, Title } from './styles';
import ArrowUpIconBlack from '../../assets/icons/arrow-up-icon-black.svg';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Formik } from 'formik';
import { motion } from 'framer-motion';

export default function TalkToUs() {

    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);

    const contactSchema = yup.object().shape({
        name: yup.string().required('Name is a required field!'),
        email: yup.string().min(10, 'E-mail must be more than 10 caracteres!').email('Invalid e-mail!').required('E-mail is a required field!'),
        number: yup.number().typeError('Just numbers!').min(5, 'Phone number must be more than 5 caracters!').required('Phone number is a required field!'),
        subject: yup.string(),
        message: yup.string().min(10, 'Message must be more than 10 caracters!').required('Message is a required field!')
    });

    const sendEmail = (emailTemplate) => {
        setIsLoading(true);

        axios.post('https://carbon-website-backend.herokuapp.com/sendEmail', emailTemplate).then(s => {
            console.log(s);
            if (s.status === 200) {
                history.push('/thank-you');
            }
        }).catch(e => {
            console.log(e);
            setIsLoading(false);
        });
    }

    const titleAnimation = {
        from: {
            x: -1000
        },
        to: {
            x: 0,
            transition: { type: "spring", stiffness: 50, duration: 1 }
        }
    }

    const inputsAnimation = {
        from: {
            opacity: 0
        },
        to: {
            opacity: 1,
            transition: { type: "spring", duration: 1 }
        }
    }

    const buttonAnimation = {
        from: {
            scale: 0.2
        },
        to: {
            scale: 1,
            transition: { type: "spring", duration: 1 }
        }
    }

    return (
        <TalkToUsContainer >
            <Formik
                validateOnChange={false}
                initialValues={{ name: '', number: '', email: '', subject: '', message: '' }}
                validationSchema={contactSchema}
                onSubmit={(values) => { sendEmail(values); }}>

                {({ handleSubmit, handleChange, values, errors }) => (
                    <div className="contents-container">

                        <Title initial="from" animate="to" variants={titleAnimation} dangerouslySetInnerHTML={{
                            __html: `Talk to us and get to know how we
                    can make your idea come alive.`}} />

                        <InputsContainer initial="from" animate="to" variants={inputsAnimation}>
                            <div className="customer-inputs-container">
                                <div className="right-container">
                                    <TalkToUsInput name="name" value={values.name} onChange={handleChange} placeholder="Your name" />
                                    <InputsErrorText>{errors.name}</InputsErrorText>
                                    <TalkToUsInput name="number" value={values.number} onChange={handleChange} placeholder="Phone number" />
                                    <InputsErrorText>{errors.number}</InputsErrorText>
                                </div>
                                <div className="left-container">
                                    <TalkToUsInput name="email" value={values.email} onChange={handleChange} placeholder="Your e-mail" />
                                    <InputsErrorText>{errors.email}</InputsErrorText>
                                    <TalkToUsInput name="subject" value={values.subject} onChange={handleChange} placeholder="Subject" />
                                    <InputsErrorText>{errors.subject}</InputsErrorText>
                                </div>
                            </div>
                            <TalkToUsInput name="message" value={values.message} onChange={handleChange} placeholder="Message" />
                            <InputsErrorText>{errors.message}</InputsErrorText>
                        </InputsContainer>

                        <motion.button initial="from" animate="to" variants={buttonAnimation} disabled={isLoading} type="submit" onClick={handleSubmit}><span>Send message</span> {isLoading ? (<Loader>Loading...</Loader>) : (<img src={ArrowUpIconBlack} alt="" />)}</motion.button>
                    </div>
                )}

            </Formik>

            <MapContainer>
            </MapContainer>
        </TalkToUsContainer >
    )
}