import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ToolbarContainer = styled.div`
  width:100%;
  /* z-index:100; */
`;

export const ToolbarContent = styled.div`
  width:100%;
  height:60px;
  display:flex;
  align-items:center;

  position:fixed;
  z-index:19;

  mix-blend-mode:difference;
  padding:50px 110px;
  
  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    padding:50px 30px;
    justify-content:space-between;
  }
  
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 767.98px) { 
    padding:50px 30px;
  }
`;

export const TalkToUsToolbar = styled.div`
  margin-left:50px;
`;

export const StyleSocialNetworks = styled(motion.div)`
  margin-left:auto;
  margin-right:40px;

  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    display:none;
  }
`;

export const CarbonTextEmail = styled.a`
  text-decoration:none;
  color:white;
  margin-left:50px;
  font-size: 18px;

  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    display:none;
  }

  :hover{
    text-decoration:underline;
  }
`;

export const ToolbarTalkToUsContainer = styled(motion.div)`
  @media (max-width: 575.98px) {
    display:none;
  }
`;