import styled from 'styled-components';
import { motion } from 'framer-motion';

export const HomeContainer = styled.section`
  width: 100%;
`;

export const VideoSection = styled.div`
  position: relative;
  z-index: 5;

  width: 100%;
  height: 100vh;

  .footer {
    position: fixed;
    z-index:2;
    bottom: 50px;
    left: 110px;
    mix-blend-mode: difference;

    p {
      height: 50px;
      margin-top: 20px;
      color: white;
      font-size: 14px;
      white-space: pre-line;
    }

    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 575.98px) {
      bottom: 20px;
      left: 20px;
    }
    
    // Small devices (landscape phones, 576px and up)
    @media (min-width: 576px) and (max-width: 767.98px) { 
      left: 50px;
    }
  }
`;

//before get video replace img to video
export const BackgroundVideo = styled.video`
  position: fixed;
  z-index: -1;

  width: 100%;
  height: 100%;
  top: 0;
  transform: translateX(0);
  left: 0;
  object-fit: cover;
`;

export const ApresentationSection = styled.section`
  z-index: 5;
  position: relative;
  overflow: hidden;

  background-color: white;
  padding: 50px 110px;
  min-height: 100vh;
  
  display: flex;
  flex-direction: column;
  
  // Small devices (landscape phones, 576px and up)
  @media (max-width: 575.98px) and (max-width: 767.98px) { 
    padding: 40px;
  }

  .apresentation-content-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const SliderSection = styled.section`
  position: relative;
  z-index: 20;
  height: 100vh;
  overflow-x: hidden;

  display: flex;
  flex-wrap: nowrap;

  background-color: black;
`;

export const TheExtraTalentSection = styled.section`
  position: relative;
  z-index: 5;
  min-height: 100vh;

  background-color: white;

  display: flex;
  flex-direction: column;

  justify-content: center;
  padding: 50px 110px;

  // Small devices (landscape phones, 576px and up)
  @media (max-width: 575.98px) and (max-width: 767.98px) { 
    padding: 40px;
  }

  p:nth-child(2) {
    font-size: 16px;
    color: #0E0E0E;
    white-space: pre-line;
    margin-bottom: 30px;
    line-height: 170%;
  }
`;

export const HomeTextTitle = styled(motion.h1)`
  font-size: ${props => props.fontSize ? props.fontSize : '60px'};
  font-weight: normal;
  letter-spacing: -0.015em;
  color: ${props => props.black ? "#0E0E0E" : "white"};
  white-space: pre-line;
  margin-bottom: 30px;

  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    font-size:30px;
    white-space:normal;
  }
`;

export const TalkToUsHomeSection = styled.section`
  background-color: black;
  height: 100vh;

  position: relative;
  z-index: 5;
  transform: translateX(0);

  .talk-to-us-home-content {
    height: 100%;
    padding: 50px 110px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    // Small devices (landscape phones, 576px and up)
    @media (max-width: 575.98px) and (max-width: 767.98px) { 
      padding: 40px;
    }
  }
`