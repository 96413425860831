import React from 'react';
import SocialNetworks from '../../../components/social-networks/SocialNetworks';
import { VideoSection, BackgroundVideo } from '../styles';
import HeaderCarbonVideo from '../../../assets/videos/header-carbon-video.mp4'

export default function HeaderVideo() {
    return (
        <VideoSection>

            <div className="footer">

                <SocialNetworks />
                <p dangerouslySetInnerHTML={{
                    __html: `The extra talent needed
                    to boost your projects!
                `
                }} />

            </div>

            <BackgroundVideo src={HeaderCarbonVideo} loop autoPlay muted />
        </VideoSection>
    )
}
