import {createGlobalStyle} from 'styled-components';
import BasierCircleRegularWebfontWOFF from '../assets/fonts/basier-circle-regular/basiercircle-regular-webfont.woff';
import BasierCircleRegularWebfontWOFF2 from '../assets/fonts/basier-circle-regular/basiercircle-regular-webfont.woff2';
import RobotoMono from '../assets/fonts/roboto-mono/RobotoMono-Regular.ttf';

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Basier Circle',sans-serif;
    /* cursor: none; */
  }

  body {
    background-color:#161616;
    scroll-behavior:smooth;
    overflow-x: hidden;
  }

  /* .cursor {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: white;
    mix-blend-mode: difference;
    z-index: 10000;
    pointer-events: none;
  } */

  @font-face {
    font-family: 'Basier Circle';
    src: local('Basier Circle'), local('Basier'),
    url(${BasierCircleRegularWebfontWOFF2}) format('woff2'),
    url(${BasierCircleRegularWebfontWOFF}) format('woff');
  }

  @font-face {
    font-family: 'Roboto Mono';
    src: url(${RobotoMono}) format('truetype');
    font-display: auto;
  }

`;