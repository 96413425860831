import React from 'react';
import { HomeContainer } from './styles';

import SliderSection from './sections/SliderSection';
import Apresentation from './sections/Apresentation.js';
import TheExtraTalent from './sections/TheExtraTalent';
import TalkToUsSection from './sections/TalkToUsSection';
import HeaderVideo from './sections/HeaderVideo';

export default function Home() {

    return (
        <HomeContainer>

            <HeaderVideo />

            <TheExtraTalent />

            <SliderSection />

            <Apresentation />

            <TalkToUsSection />

        </HomeContainer>
    )
}
