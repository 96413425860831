import React, { useState } from 'react';
import { ToolbarContainer, StyleSocialNetworks, CarbonTextEmail, ToolbarContent, ToolbarTalkToUsContainer } from './styles';
import CarbonLogo from '../carbon-logo/CarbonLogo';
import SocialNetworks from '../social-networks/SocialNetworks';
import TalkToUs from '../talk-to-us/TalkToUs';
import Menu from '../menu/Menu';
import { useLocation } from 'react-router-dom';
import { HamburgerElastic } from 'react-animated-burgers'

import { useViewportScroll, useTransform } from 'framer-motion';

export default function Toolbar() {

    const location = useLocation();

    const { scrollY } = useViewportScroll()

    const talkToUsOpacityAnim = useTransform(scrollY, [0, 1000], [1, 0]);
    const socialNetworksOpacityAnim = useTransform(scrollY, [0, 1000], [0, 1]);

    const [isOpen, setIsOpen] = useState(false);

    return (
        <ToolbarContainer>

            <ToolbarContent>
                <CarbonLogo />

                {
                    location.pathname === "/talk-to-us" || location.pathname === "/thank-you" ?
                        (
                            <CarbonTextEmail href="mailto:hello@carbon.com.br">hello@carbonlab.tech</CarbonTextEmail>
                        ) : (
                            <ToolbarTalkToUsContainer style={{ opacity: talkToUsOpacityAnim }}>
                                <TalkToUs marginLeft="50px" />
                            </ToolbarTalkToUsContainer>
                        )
                }

                <StyleSocialNetworks style={{ opacity: location.pathname === "/talk-to-us" || location.pathname === "/thank-you" ? 1 : socialNetworksOpacityAnim }}>
                    <SocialNetworks color="black" />
                </StyleSocialNetworks>

                <HamburgerElastic buttonWidth={30} barColor="white" isActive={isOpen} toggleButton={()=> setIsOpen(!isOpen)} />
            </ToolbarContent>

            <Menu isOpen={isOpen} onClose={()=> setIsOpen(false)}/>
        </ToolbarContainer>
    )
}
