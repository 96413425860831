import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/home/Home';
import TalkToUs from './pages/talk-to-us/TalkToUs';
import Toolbar from './components/toolbar/Toolbar';
import ThankYou from './pages/thank-you/ThankYou';

function App() {
  return (
    <Router>
      <Toolbar />
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/talk-to-us" component={TalkToUs} exact />
        <Route path="/thank-you" component={ThankYou} exact />
      </Switch>
    </Router>
  );
}

export default App;