import React, { forwardRef } from 'react';
import {
    StyleSliderItem,
    BackgroundImage,
    BackgroundColor,
    StyleContentTextsContainer,
    StyleSliderItemHeader,
    StyleContentText,
    StyleSliderItemContent,
    StyleContentContainer,
    StyleBuildedWithContainer
} from './styles';

const SliderItem = forwardRef(({ buildedWith, index, name, bgImage, backgroundColor = "black", projectLink }, ref) => {

    const spring = {
        type: "spring",
        damping: 10,
        duration: 0.4
    }

    const backgroundColorHover = {
        from: {
            display: "none",
            opacity: 0.3
        },
        hover: {
            display: "block",
            opacity: 1
        }
    }

    const backgroundImageHover = {
        from: {
            display: "none",
            y: 100,
            x: 70
        },
        hover: {
            display: "block",
            y: 0,
            x: 0
        }
    }

    const contentContainerHover = {
        from: {
            y: 0
        },
        hover: {
            y: -50
        }
    }

    return (

        <StyleSliderItem ref={ref} initial="from" whileHover="hover" whileTap="hover" animate="from" onClick={() => window.open(projectLink, '_blank')}>

            <StyleSliderItemContent>

                <StyleSliderItemHeader>
                    <StyleBuildedWithContainer>
                        <p>{buildedWith}</p>
                    </StyleBuildedWithContainer>
                </StyleSliderItemHeader>

                <StyleContentContainer transition={spring} variants={contentContainerHover}>
                    <StyleContentTextsContainer>
                        <StyleContentText fontFamily="Roboto Mono" fontSize="96px">{String(index + 1).padStart(2, '0')}</StyleContentText>
                        <StyleContentText fontSize="36px">{name}</StyleContentText>
                    </StyleContentTextsContainer>
                </StyleContentContainer>

            </StyleSliderItemContent>

            <BackgroundColor transition={spring} variants={backgroundColorHover} backgroundColor={backgroundColor} />
            <BackgroundImage transition={spring} variants={backgroundImageHover} loading="eager" src={bgImage} />


        </StyleSliderItem>
    );


});


export default SliderItem;